import { Button } from 'app/components/Button';
import { BodyMediumBold } from 'app/components/Text';
import { uploadFile } from 'app/library/FileUploader';
import { getUploadUrl } from 'app/library/RichMediaAPI';
import { appActions } from 'app/slice';
import { selectUser } from 'app/slice/selectors';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RichMedia } from 'types/RichMedia';
import { User } from 'types/User';

type Props = {
  onFileUpload: (url: string) => void;
  disabled?: boolean;
};

export const FileUpload = ({ onFileUpload, disabled }: Props) => {
  const user: User | undefined = useSelector(selectUser);
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      saveOnClick(file);
    }
  };

  const uploadOnClick = event => {
    (fileInput.current as any)?.click();
  };

  const saveOnClick = async (file: File) => {
    try {
      if (!user) {
        throw new Error('User not logged in.');
      }
      dispatch(appActions.setLoading(true));
      const richMedia: RichMedia = await getUploadUrl(
        'image/jpeg',
        user.company!,
      );
      await uploadFile(richMedia.uploadUrl, file);
      onFileUpload(richMedia.url!);
      dispatch(appActions.setLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(appActions.setLoading(false));
      dispatch(appActions.setError('Error in uploading banner.'));
    }
  };

  return (
    <>
      <input
        ref={fileInput}
        hidden
        type="file"
        onChange={onFileChange}
        accept="image/*"
      />
      <Button
        color="white"
        onClick={uploadOnClick}
        buttonStyles={{ padding: '6px 16px', height: '34px' }}
        disabled={disabled}
      >
        <BodyMediumBold>Upload custom image</BodyMediumBold>
      </Button>
    </>
  );
};
