import { Apollo } from 'app/library/Apollo';
import { TeammatesSort } from 'app/pages/TeammatesPage/slice/types';
import { PlatformUser } from 'types/PlatformUser';
import {
  InstagramMedia,
  LinkedInAuth0Details,
  SlackIdentity,
  SocialMedia,
  User,
  UserLocation,
} from 'types/User';

// TODO instead of React calling initAuth after every update, just use the user in the return object

const PAGE_SIZE = 100;

export interface GQLQuery {
  query: string;
  variables?: any;
}

export function getPegasusUser(userId = undefined) {
  const [query, user] = !!userId
    ? ['query($id: UserId)', 'user(id: $id)']
    : ['query', 'user'];

  let data: GQLQuery = {
    query: `
      ${query} {
        ${user} {
          id
          superUser
          email
          firstName
          lastName
          username
          funName
          birthday
          startDate
          unknownStartDate
          interests
          phoneNumber
          image {
            url
          }
          funImage {
            url
          }
          location
          department
          title
          funTitle
          organization
          socialMedias {
            name
            link
          }
          companies {
            id
            emailDomain
            name
            interests
          }
          slackWorkspaces {
            id
            name
          }
          slackIdentities {
            type
            slackUserId
            username
            teamId
            workspaceName
            slackWorkspaceId
          }
          secondaryEmails {
            id
            email
            verified
          }
          createdAt
          features {
            onboarding
          }
          locations {
            id
            type
            geoLocation
            name
          }
          profilePageViews
          companyName
          timezone
        }
      }
    `,
  };

  if (!!userId) {
    data.variables = { id: userId };
  }

  return Apollo.post(data)
    .then(response => response.data.data.user)
    .catch(err => Apollo.handleError(err, data));
}

export async function getCurrentUser(): Promise<User | undefined> {
  let data: GQLQuery = {
    query: `
      query {
        user {
          id
          superUser
          email
          firstName
          lastName
          image {
            url
          }
          title
          slackWorkspaces {
            id
            name
          }
          slackIdentities {
            type
            slackUserId
            username
            teamId
            workspaceName
            slackWorkspaceId
          }
          createdAt
          features {
            onboarding
            billing
          }
          timezone
        }
      }
    `,
  };
  try {
    const res = await Apollo.post(data);
    const currentUser = transformCurrentUser(res.data.data.user);
    return currentUser;
  } catch (err) {
    Apollo.handleError(err, data);
    console.error('Could not get current user');
    console.error(err);
  }
}

function transformCurrentUser(rawUser): User | undefined {
  try {
    const user = {
      ...rawUser,
      slackIdentities: transformSlackIdentities(rawUser.slackIdentities ?? []),
      image: rawUser.image?.url,
      hasPets: false,
      onboarding: rawUser.features?.onboarding,
      billing: rawUser.features?.billing,
    };
    return user;
  } catch (err) {
    console.error('Could not transform raw user to current user');
    console.error(err);
  }
}

export function getUser(userId = undefined): Promise<User | undefined> {
  return getPegasusUser(userId).then(user => {
    return transformPegasusUser(user);
  });
}

export function transformPegasusUser(pegasusUser): User | undefined {
  try {
    const user = {
      id: pegasusUser.id || '',
      superUser: pegasusUser.superUser || false,
      email: pegasusUser.email || '',
      firstName: pegasusUser.firstName || '',
      lastName: pegasusUser.lastName || '',
      funName: pegasusUser.funName,
      username: pegasusUser.username || '',
      birthday: pegasusUser.birthday || undefined,
      workAnniversary: pegasusUser.startDate || undefined,
      unknownStartDate: pegasusUser.unknownStartDate || false,
      location: pegasusUser.location || undefined,
      department: pegasusUser.department || undefined,
      title: pegasusUser.title || undefined,
      funTitle: pegasusUser.funTitle || undefined,
      phoneNumber: pegasusUser.phoneNumber || undefined,
      socialMedias: pegasusUser.socialMedias,
      interests: transformInterests(pegasusUser.interests),
      company: transformCompanies(
        pegasusUser.companies,
        pegasusUser.slackWorkspaces,
      ),
      slackWorkspaces: transformSlackWorkspaces(pegasusUser.slackWorkspaces),
      slackIdentities: transformSlackIdentities(
        pegasusUser.slackIdentities ?? [],
      ),
      image: pegasusUser.image?.url,
      funImage: pegasusUser.funImage?.url,
      secondaryEmail: transformSecondaryEmails(pegasusUser.secondaryEmails),
      secondaryEmails: pegasusUser.secondaryEmails,
      hasPets: (pegasusUser.families || []).length > 0,
      organization: pegasusUser.organization,
      birthdayQuestion: transformBirthday(pegasusUser.events),
      workAnniversaryQuestion: transformWorkAnniversary(pegasusUser.events),
      createdAt: pegasusUser.createdAt,
      redemptionCode: pegasusUser.redemptionCode || undefined,
      onboarding: pegasusUser.features?.onboarding,
      locations: pegasusUser.locations,
      profilePageViews: pegasusUser.profilePageViews,
      companyName: pegasusUser.companyName,
      timezone: pegasusUser.timezone,
      billing: pegasusUser.features?.billing || false,
    };
    return user;
  } catch (err) {
    console.error('Could not get Pegasus user');
    console.error(err);
  }
}

function transformBirthday(pegasusEvents) {
  return !!pegasusEvents
    ? !!pegasusEvents.birthday
      ? pegasusEvents.birthday
      : undefined
    : undefined;
}

function transformWorkAnniversary(pegasusEvents) {
  return !!pegasusEvents
    ? !!pegasusEvents.workAnniversary
      ? pegasusEvents.workAnniversary
      : undefined
    : undefined;
}

function transformInterests(pegasusInterests) {
  return pegasusInterests?.map(pegasusInterest => {
    return { name: pegasusInterest };
  });
}

function transformCompanies(pegasusCompanies, pegasusWorkspaces) {
  if (pegasusCompanies?.length > 0) {
    const pegasusCompany = pegasusCompanies[0];
    return {
      id: pegasusCompany.id,
      name: pegasusCompany.name,
      emailDomain: pegasusCompany.emailDomain,
      allowUsersToJoin: pegasusCompany.allowUsersToJoin,
      hasImage: !!pegasusCompany.image,
      interests: pegasusCompany.interests,
    };
  }

  const workspaces = transformSlackWorkspaces(pegasusWorkspaces);
  if (!!workspaces?.length) {
    return workspaces[0];
  }
}

function transformSlackWorkspaces(pegasusCompanies?: any[]): Company[] {
  if (!pegasusCompanies?.length) {
    return [];
  }
  return pegasusCompanies.map(pegasusCompany => {
    return {
      id: pegasusCompany.id,
      name: pegasusCompany.name,
      emailDomain: '',
      allowUsersToJoin: true,
      hasImage: false,
      interests: [],
    };
  });
}

function transformSlackIdentities(
  identities: SlackIdentity[],
): SlackIdentity[] {
  return identities.map(id => {
    const parts = id.slackUserId.split('-');
    const userId = parts[parts.length - 1];
    id.slackUserId = userId;
    return id;
  });
}

function transformSecondaryEmails(secondaryEmails) {
  return !!secondaryEmails
    ? secondaryEmails.length === 0
      ? undefined
      : {
          id: secondaryEmails[0].id,
          email: secondaryEmails[0].email,
          verified: secondaryEmails[0].verified,
        }
    : undefined;
}

function handleUserUpdate(data) {
  return Apollo.post(data)
    .then(response => response.data.data.updateUser)
    .catch(err => Apollo.handleError(err, data));
}

export function updateBirthday(birthday) {
  const data = {
    query: `
      mutation updateUser($birthday: Day) {
        updateUser(input: { birthday: $birthday }) {
          id
          birthday
        }
      }
    `,
    variables: { birthday: birthday },
  };

  return handleUserUpdate(data);
}

export function updateAnniversary(workAnniversary) {
  const data = {
    query: `
      mutation updateUser($startDate: LocalDate) {
        updateUser(input: { startDate: $startDate }) {
          id
          startDate
        }
      }
    `,
    variables: { startDate: workAnniversary },
  };

  return handleUserUpdate(data);
}

export function updateFirstName(firstName) {
  const data = {
    query: `
      mutation updateUser($firstName: String) {
        updateUser(input: { firstName: $firstName }) {
          id
          firstName
        }
      }
    `,
    variables: { firstName: firstName },
  };

  return handleUserUpdate(data);
}

export function updateLastName(lastName) {
  const data = {
    query: `
      mutation updateUser($lastName: String) {
        updateUser(input: { lastName: $lastName }) {
          id
          lastName
        }
      }
    `,
    variables: { lastName: lastName },
  };

  return handleUserUpdate(data);
}

export function updateUsername(username) {
  const data = {
    query: `
      mutation updateUser($username: String) {
        updateUser(input: { username: $username }) {
          id
          username
        }
      }
    `,
    variables: { username: username },
  };

  return handleUserUpdate(data);
}

export function updateFunName(funName) {
  const data = {
    query: `
      mutation updateUser($funName: String) {
        updateUser(input: { funName: $funName }) {
          id
          funName
        }
      }
    `,
    variables: { funName: funName },
  };

  return handleUserUpdate(data);
}

export function updateWorkTitle(title) {
  const data = {
    query: `
      mutation updateUser($title: String) {
        updateUser(input: { title: $title }) {
          id
          title
        }
      }
    `,
    variables: { title: title },
  };

  return handleUserUpdate(data);
}

export function updateFunTitle(funTitle) {
  const data = {
    query: `
      mutation updateUser($funTitle: String) {
        updateUser(input: { funTitle: $funTitle }) {
          id
          funTitle
        }
      }
    `,
    variables: { funTitle: funTitle },
  };

  return handleUserUpdate(data);
}

function createUpdateOneFieldQuery(fieldName, fieldType = 'String') {
  return `
    mutation updateUser($${fieldName}: ${fieldType}) {
      updateUser(input: { ${fieldName}: $${fieldName} }) {
        id
      }
    }
  `;
}

export function updateOrganization(organization) {
  const data = {
    query: createUpdateOneFieldQuery('organization'),
    variables: { organization: organization },
  };
  return handleUserUpdate(data);
}

export function updateCompanyName(companyName) {
  const data = {
    query: createUpdateOneFieldQuery('companyName'),
    variables: { companyName },
  };
  return handleUserUpdate(data);
}

export function updateUnknownStartDate(unknownStartDate) {
  const data = {
    query: `
      mutation updateUser($unknownStartDate: Boolean) {
        updateUser(input: { unknownStartDate: $unknownStartDate }) {
          id
          unknownStartDate
        }
      }
    `,
    variables: { unknownStartDate: unknownStartDate },
  };

  return handleUserUpdate(data);
}

export function updateLocation(location: UserLocation, companyId?: string) {
  const data = {
    query: `
      mutation addLocation($input: AddUserLocationInput!) {
        user {
          addLocation(input: $input) {
            id
          }
        }
      }
    `,
    variables: {
      input: {
        companyId: companyId,
        name: location.name,
        geolocation: location.geoLocation,
        type: location.type,
        startDate: location.startDate,
        endDate: location.endDate,
      },
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.user.addLocation)
    .catch(err => Apollo.handleError(err, data));
}

export function removeLocations(locations: UserLocation[]) {
  const data = {
    query: `
      mutation removeLocation($input: RemoveUserLocationInput!) {
        user {
          removeLocation(input: $input)
        }
      }
    `,
    variables: {
      input: {
        ids: locations.map(l => l.id).filter(_ => !!_),
      },
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.user.addLocation)
    .catch(err => Apollo.handleError(err, data));
}

export function removeAndAddLocation(
  companyId: string,
  location: UserLocation,
) {
  // There is no updateLocation mutation in the backend
  // First call removeLocation and then addLocation in the same graphql request
  const data = {
    query: `
      mutation updateLocation($input: RemoveUserLocationInput!, $addInput: AddUserLocationInput!) {
        user {
          removeLocation(input: $input)
          addLocation(input: $addInput) {
            id
          }
        }
      }`,
    variables: {
      input: {
        ids: [location.id],
      },
      addInput: {
        companyId: companyId,
        name: location.name,
        geolocation: location.geoLocation,
        type: location.type,
        startDate: location.startDate,
        endDate: location.endDate,
      },
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.user.addLocation)
    .catch(err => Apollo.handleError(err, data));
}
export function updateInterests(interests) {
  const data = {
    query: `
      mutation updateUser($interests: [String!]!) {
        updateUser(input: {}) {
          updateInterests(input: { interests: $interests })
        }
      }
    `,
    variables: { interests: interests.map(interest => interest.name) },
  };
  return handleUserUpdate(data);
}

export function invitePeople(emails) {
  return Promise.all(emails.map(email => inviteOne(email))).then(
    responses => responses,
  );
}

function inviteOne(recipient) {
  const data = {
    query: `
      mutation send($recipient: EmailAddress!) {
        sendInvite(
          input: { recipient: $recipient }
        ) {
          success
          errorMessage
        }
      }
    `,
    variables: { recipient: recipient },
  };

  return Apollo.post(data)
    .then(response => response.data.data.sendInvite)
    .catch(err => Apollo.handleError(err, data));
}

export function updateSocialMedias(socialMedias: SocialMedia[]) {
  const data = {
    query: `
      mutation updateUser(
        $socialMedias: [SocialMediaInput!]
      ) {
        updateUser(
          input: {
            socialMedias: $socialMedias
          }
        ) {
          socialMedias {
            name
            link
          }
        }
      }
    `,
    variables: {
      socialMedias: socialMedias,
    },
  };
  return handleUserUpdate(data);
}

export function updateTitle(title) {
  const data = {
    query: createUpdateOneFieldQuery('title'),
    variables: { title: !!title ? title : null },
  };
  return handleUserUpdate(data);
}

export function updateDepartment(department) {
  const data = {
    query: createUpdateOneFieldQuery('department'),
    variables: { department: !!department ? department : null },
  };
  return handleUserUpdate(data);
}

export function updatePhoneNumber(phoneNumber) {
  const data = {
    query: createUpdateOneFieldQuery('phoneNumber', 'PhoneNumber'),
    variables: { phoneNumber: !!phoneNumber ? phoneNumber : null },
  };
  return handleUserUpdate(data);
}

export function updateImage(richMediaId, field = 'image') {
  const data = {
    query: createUpdateOneFieldQuery(field, 'RichMediaId'),
    variables: { [field]: richMediaId },
  };
  return handleUserUpdate(data);
}

export function updateFunImage(richMediaId: string) {
  return updateImage(richMediaId, 'funImage');
}

export function addFamilyMember(name): Promise<string> {
  const data = {
    query: `
      mutation addFamilyMember($name: String!) {
        updateUser(input: {}) {
          addFamilyMember(input: { name: $name }) {
            id
          }
        }
      }
    `,
    variables: { name: name },
  };
  return Apollo.post(data)
    .then(response => response.data.data.updateUser.addFamilyMember.id)
    .catch(err => Apollo.handleError(err, data));
}

export function updateFamilyMemberName(familyMemberId, name) {
  const data = {
    query: `
      mutation updateFamilyMember($name: String!, $id: FamilyMemberId!) {
        updateUser(input: {}) {
          updateFamilyMember(input: { name: $name, id: $id }) {
            id
            name
          }
        }
      }
    `,
    variables: { name: name, id: familyMemberId },
  };
  return Apollo.post(data)
    .then(response => response.data.data.updateUser.updateFamilyMember)
    .catch(err => Apollo.handleError(err, data));
}

export function updateFamilyMemberImage(familyMemberId, richMediaId, name) {
  const data = {
    query: `
      mutation updateFamilyMember($image: RichMediaId!, $id: FamilyMemberId!, $name: String!) {
        updateUser(input: {}) {
          updateFamilyMember(input: { image: $image, id: $id, name: $name }) {
            id
            image {
              url
            }
          }
        }
      }
    `,
    variables: { image: richMediaId, id: familyMemberId, name: name },
  };
  return Apollo.post(data)
    .then(response => {
      return response.data.data.updateUser.updateFamilyMember;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function deleteFamilyMember(familyMemberId) {
  const data = {
    query: `
      mutation addFamilyMember($id: FamilyMemberId!) {
        updateUser(input: {}) {
          deleteFamilyMember(input: { id: $id })
        }
      }    
    `,
    variables: { id: familyMemberId },
  };
  return Apollo.post(data)
    .then(response => response.data.data.updateUser.deleteFamilyMember)
    .catch(err => Apollo.handleError(err, data));
}

export function addSecondaryEmail(email) {
  const data = {
    query: `
      mutation addSecondaryEmail($email: EmailAddress!) {
        user {
          addSecondaryEmail(email: $email) {
            id
            email
            verified
          }
        }
      }
    `,
    variables: { email: email },
  };

  return Apollo.post(data).then(response => {
    if (response.data.errors) {
      throw new Error(response.data.errors[0]?.message);
    }
    return response.data.data.user.addSecondaryEmail;
  });
}

export function deleteSecondaryEmail(id) {
  const data = {
    query: `
      mutation updateUser($id: SecondaryEmailId!) {
        updateUser(input: {}) {
          deleteSecondaryEmail(id: $id)
        }
      }
    `,
    variables: { id: id },
  };
  return Apollo.post(data)
    .then(response => response.data.data.updateUser.deleteSecondaryEmail)
    .catch(err => Apollo.handleError(err, data));
}

export function updateFeatures(onboarding: Boolean) {
  const data = {
    query: `
      mutation updateFeatures($onboarding: Boolean) {
        updateUser(input: {}) {
          updateFeatures(input: { onboarding: $onboarding }) {
            onboarding
          }
        }
      }
    `,
    variables: { onboarding: onboarding },
  };
  return Apollo.post(data)
    .then(response => {
      return response.data.data.updateUser.updateFeatures;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function getCompanyUsers(
  companyId,
  sortBy,
  anyOfInterests,
  offset = 0,
): Promise<User[]> {
  const orderBy =
    sortBy === TeammatesSort.BIRTHDAY
      ? null
      : `{ field: ${sortBy}, order: ASC }`;
  const data = {
    query: `
      query list($companyId: CompanyId!, $first: Int!, $offset: Int!, $anyOfInterests: [String!]) {
        listUsers(
          input: { companyId: $companyId, first: $first, offset: $offset, filter: { anyOfInterests: $anyOfInterests }, orderBy: ${orderBy} }) {
          items {
            id
            email
            firstName
            lastName
            birthday
            startDate
            interests
            image {
              url
            }
            location
            department
            title
            companies {
              id
              emailDomain
              name
              interests
            }
            events {
              birthday
              workAnniversary
            }
            createdAt
          }
          first
          offset
          totalCount
          nextToken
        }
      }
    `,
    variables: {
      companyId: companyId,
      first: PAGE_SIZE,
      offset: offset,
      anyOfInterests: anyOfInterests,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.listUsers.items)
    .then(items => items.map(item => transformPegasusUser(item)))
    .catch(err => Apollo.handleError(err, data));
}

export function updateTimezone(timezone) {
  const data = {
    query: createUpdateOneFieldQuery('timezone', 'Timezone'),
    variables: { timezone: !!timezone ? timezone : null },
  };
  return handleUserUpdate(data);
}

export function getPlatformUsers({
  first = 20,
  offset = 0,
  platform = 'SLACK',
  keyword,
}: {
  first?: number;
  offset?: number;
  platform?: string;
  keyword?: string;
}): Promise<PlatformUser[]> {
  const data = {
    query: `
      query list($first: Int!, $offset: Int!, $platform: IntegrationPlatform!, $keyword: String) {
        getPlatformUsers(input: { first: $first, offset: $offset, platform: $platform, keyword: $keyword }) {
          items {
            id
            teamId
            name
            email
          }
          first
          offset
          totalCount
          nextToken
        }
      }
    `,
    variables: {
      first: first,
      offset: offset,
      platform: platform,
      keyword: keyword,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getPlatformUsers.items)
    .catch(err => Apollo.handleError(err, data));
}

export const getCompanyName = (user?: User): string => {
  if (!user || !user.company) {
    return '';
  }
  return !!user.companyName?.length ? user.companyName : user.company.name;
};

export function _deactivateUser(userId, companyId) {
  let data: GQLQuery = {
    query: `
      query($userId: UserId!, $companyId: CompanyId!) {
        deactivateUser(userId: $userId, companyId: $companyId)
      }
    `,
    variables: {
      userId: userId,
      companyId: companyId,
    },
  };
  console.log('data', data);
  return Apollo.post(data)
    .then(response => {
      return response.data.data.deactivateUser;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function deactivateUser(userId, companyId): Promise<any> {
  return _deactivateUser(userId, companyId)
    .then(userId => {
      return userId;
    })
    .catch(() => {
      return undefined;
    });
}

export const getLinkedInDetails = (
  userId: string,
): Promise<LinkedInAuth0Details> => {
  const data: GQLQuery = {
    query: `
      query getLinkedinDetails($input: Auth0UserInput!) {
        getLinkedInDetails(input: $input) {
          name
          givenName
          familyName
          headline
          picture
          userId
          vanityName
          nickname
          email
          emailVerified
        }
      }
    `,
    variables: {
      input: {
        id: userId,
      },
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getLinkedInDetails)
    .catch(err => Apollo.handleError(err, data));
};

export const getInstagramMedias = (
  userId: string,
): Promise<InstagramMedia[]> => {
  const data: GQLQuery = {
    query: `
      query getInstagramMedias($input: Auth0UserInput!) {
        getInstagramMedias(input: $input) {
          mediaUrl
        }
      }
    `,
    variables: {
      input: {
        id: userId,
      },
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getInstagramMedias ?? [])
    .catch(err => Apollo.handleError(err, data));
};

export function getConversationURL(users: string[], companyId: string) {
  const data = {
    query: `
      mutation createSlackConversation($input: CreateSlackConversationInput!) {
        user {
          createSlackConversation(input: $input) {
            conversationURL
          }
        }
      }
    `,
    variables: {
      input: {
        companyId: companyId,
        users: users,
      },
    },
  };

  return Apollo.post(data)
    .then(
      response =>
        response.data.data.user.createSlackConversation.conversationURL,
    )
    .catch(err => Apollo.handleError(err, data));
}

export function logoutUser() {
  const data = {
    query: `
      query logout {
        logout
      }
    `,
  };

  return Apollo.post(data)
    .then(response => response.data.data.logout)
    .catch(err => Apollo.handleError(err, data));
}
